/* Add this CSS to your existing stylesheet or create a new one */
.react-tel-input {
  height: 48px;
  width: 100% !important;
  box-sizing: border-box; /* Include padding and border in the total width/height */
}

.react-tel-input input {
  height: 100%;
  width: 100% !important;
  padding: 12px; /* Adjust the padding as needed */
  box-sizing: border-box;
  border: 1px solid #ccc; /* Add border styles as needed */
  border-radius: 8px; /* Adjust the border-radius as needed */
  outline: none; /* Remove the default outline style */
}

.flag-dropdown {
  /* background-color: rgb(240, 205, 159) !important; */
  border-right: 1px solid #ddd !important;
}
