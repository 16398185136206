@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&family=Roboto:wght@300;400&display=swap");
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-family: "Roboto", sans-serif;
  padding: 0;
}
