.content-container {
  min-height: calc(100vh - 24px);
}

.footer--pin {
  position: relative;
  left: 0;
  bottom: 0;
}

.logo {
  object-fit: contain;
  width: 40%;
  height: 40%;
  position: relative;
  top: 0;
}
